<template>
  <RevDrawer
    :close-button-label="i18n(translations.closeButtonLabel)"
    :has-padding="false"
    :name="MODAL_NAMES.VISIBLE_BY_VERIZON"
    :title="i18n(translations.drawerTitle)"
  >
    <template #body>
      <VisibleByVerizonDrawerBody />
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDrawer } from '@ds/components/Drawer'

import { MODAL_NAMES } from '~/scopes/product/constants'

import translations from './VisibleByVerizonDrawer.translations'
import VisibleByVerizonDrawerBody from './components/VisibleByVerizonDrawerBody/VisibleByVerizonDrawerBody.vue'

const i18n = useI18n()
</script>
